import React, { useRef } from 'react'
import { Link } from 'gatsby'
import Layout from '../layouts'
import SEO from '../components/seo'
import Image from '../components/image'
import Intro from '../components/intro'
import Content from '../components/content'
import Info from '../components/info'
import Block from '../components/block'
import { animated } from 'react-spring'
import useIsInViewport from 'use-is-in-viewport'
import useAnimate from '../hooks/useAnimate'
import P from '../components/paragraph'

const AnimatedBlock = animated(Block)
const AnimatedImage = animated(Image)

const Wedding = () => {
  const fadeIn = useAnimate()
  const notesRef = useRef(null)
  const [isInViewport, wrappedNotesRef] = useIsInViewport({
    target: notesRef,
    modBottom: '-10%',
  })
  const fadeInTrigger = useAnimate({ trigger: isInViewport })

  return (
    <Layout>
      <SEO title='The Wedding' />
      <Intro>
        <h1>The Wedding</h1>
        <P narrow>
          All the important information you&apos;ll need to know before joining
          us on our wedding day.
        </P>
      </Intro>
      <AnimatedImage style={fadeIn(250)} file='rings' height={300} />
      <Content css='margin-top: 2em' columns top>
        <AnimatedBlock style={fadeIn(500)}>
          <h3>Ceremony</h3>
          <p>
            If day guests could please arrive from 10am to be seated for a 11am ceremony.
            You can find the address below; further information can be
            found on the <Link to='/venue'>Venue &amp; travel</Link> page.
          </p>
          <Info>
            11am on 6th October 2024
            <br />
            Old Thorns, Longmoor Road, Liphook GU30 7PE
            <br />
            <Link to='/venue'>Map & Directions &rarr;</Link>
          </Info>
        </AnimatedBlock>
        <AnimatedBlock style={fadeIn(750)}>
          <h3>Reception</h3>
          <p>
            Evening guests are welcome to join in the celebrations from 6pm. The reception will take place at the same venue as the ceremony.
          </p>
          <Info>
            6pm on 6th October 2024
            <br />
            Old Thorns, Longmoor Road, Liphook GU30 7PE
            <br />
            <Link to='/venue'>Map & Directions &rarr;</Link>
          </Info>
        </AnimatedBlock>
      </Content>

      <Content css='margin-top: 2em;' columns top center>
        <AnimatedBlock style={fadeInTrigger(0)} css='min-width: 50%;'>
          <h3 ref={wrappedNotesRef}>Order of the day</h3>
          <Info>
            <ul css='list-style-type: none'>
              <li><strong>10:00 - 11:00</strong> - Arrival of guests</li>
              <li><strong>11:00 - 11:30</strong> - Ceremony</li>
              <li><strong>11:30 - 13:30</strong> - Reception Drinks and Wedding Photographs</li>
              <li><strong>13:30 - 15:30</strong> - Wedding Breakfast</li>
              <li><strong>15:30 - 16:00</strong> - Speeches</li>
              <li><strong>18:00 - 00:00</strong> - Evening Reception</li>
            </ul>
          </Info>
        </AnimatedBlock>
      </Content>

      <Content css='margin-top: 2em' columns top>
        <AnimatedBlock style={fadeInTrigger(0)}>
          <h3>Important Notes</h3>
          <p>
            Just a few considerations to keep in mind:
          </p>
          <Info>
            <ol>
              <li>Parking is available outside the front of the venue, free of charge</li>
              <li>The bar accepts both Cash & Card payments</li>
              <li>We hope you will understand our decision to make the wedding children-free, and take the opportunity to let your hair down and celebrate in style!</li>
            </ol>
          </Info>
        </AnimatedBlock>
        <AnimatedBlock style={fadeInTrigger(250)}>
          <h3>Gifts</h3>
          <p>
            Your presence at our wedding is the best gift we could receive. But for those who feel they would like to contribute, we would be grateful for donations towards our honeymoon so we can truly make it a once in a lifetime trip.
          </p>
        </AnimatedBlock>
      </Content>
    </Layout>
  )
}

export default Wedding
